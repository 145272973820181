<script>
import { storeForms } from '@app/mixins/forms';
import OAuthClerkForm from '@app/components/OAuthClerkForm.vue';

export default {
  components: { OAuthClerkForm },
  mixins: [storeForms],
  head: {
    title: 'SSO (Single Sign-On)',
  },
  form: {
    'saml.btn_text': {
      type: 'text',
      column: 'is-12',
      label: 'Texte du bouton de connexion',
      tooltip: `
            Texte qui sera affiché sur le bouton de connexion via le SSO,
            visible sur la page de connexion de votre espace de formation
          `,
      tooltipAttrs: {
        position: 'is-left',
        multilined: true,
      },
      inputAttrs: {
        required: true,
      },
    },
    domains: {
      label: 'Extensions des adresses emails autorisées (séparées par des virgules)',
      type: 'taginput',
      column: 'is-12',
      tooltip: `
            Liste des extentions des adresses emails autorisées à se connecter
            via le SSO, du type "monentreprise.fr".
            Laissez vide pour ne pas filtrer par extensions
          `,
      tooltipAttrs: {
        position: 'is-left',
        multilined: true,
      },
      inputAttrs: {
        ellipsis: true,
        icon: 'chevron-right',
        placeholder: 'Ajouter un domaine (laisser vide pour tout autoriser)',
      },
    },
    attach_all_trainings: {
      switchLabel: 'Inscrire automatiquement les nouveaux apprenants à toutes les formations',
      type: 'switch',
      column: 'is-12',
      tooltip: `
            En activant cette option, les nouveaux apprenants auront automatiquement accès
            à toutes les formations publiées, non répertoriées et cachées de votre espace au moment
            de leur inscription. Cette option n'aura aucun impact pour les apprenants existants.
          `,
      tooltipAttrs: {
        position: 'is-left',
        multilined: true,
      },
      inputAttrs: {
      },
    },
  },
  data() {
    return {
      store: {
        sso_options: {
          saml: {
            enabled: false,
            btn_text: '',
          },
          attach_all_trainings: false,
          domains: [],
        },
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
  },
};
</script>

<template>
  <div>
    <AppFeatureWrapper>
      <template #title>
        Configuration SSO
      </template>
      <template #content>
        <p>
          Configurez les informations liées à la connexion
          <strong>Single Sign-On</strong> (SSO) de vos apprenants.
        </p>
        <p>
          Le SSO permet à vos salariés de se connecter en un clic à leur espace apprenant sur Teachizy,
          <strong>sans avoir à saisir de mot de passe</strong>.
          Pour cela, ils devront au préalable s'authentifier au réseau de votre entreprise.
        </p>
      </template>

      <template #form>
        <div
          v-if="!store.sso_options.saml.enabled"
          class="box"
        >
          <p class="notification is-danger">
            <b-icon icon="times" />
            Connexion SSO par SAML inactive
          </p>
          <p>
            Nous proposons des solutions dédiées à la formation interne et l’onboarding en entreprise,
            toujours avec la flexibilité & la simplicité de Teachizy, telle que la connexion SSO.
          </p>
          <p class="mt-2 has-text-weight-bold">
            Cette fonctionnalité vous intéresse ?
            Veuillez nous contacter à <a href="mailto:allo@teachizy.fr">allo@teachizy.fr</a>
            pour en discuter !
          </p>
        </div>
        <form
          v-else
          ref="form"
          class="box"
          @submit.prevent="dataIsValid(isLoading) && handle()"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <p class="notification is-success">
                <b-icon icon="check" />
                Connexion SSO par SAML active
              </p>
            </div>
            <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
              <GeneratorField :fieldName="k" :fieldDefinition="field" :formData.sync="store.sso_options" />
            </div>
          </div>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </template>
    </AppFeatureWrapper>

    <AppFeatureWrapper>
      <template #title>
        Configuration Clerk OAuth
      </template>
      <template #content>
        <p>
          Configurez les informations liées à la connexion
          <strong>OAuth</strong> de vos apprenants.
        </p>
        <p>
          L'OAuth permet à vos utilisateurs de se connecter en un clic à leur espace apprenant sur Teachizy,
          <strong>sans avoir à saisir de mot de passe</strong>.
          Pour cela, ils devront au préalable s'authentifier au réseau de votre entreprise.
        </p>
      </template>

      <template #form>
        <div
          v-if="!store.sso_options.clerk.enabled"
          class="box"
        >
          <p class="notification is-danger">
            <b-icon icon="times" />
            Connexion OAuth par Clerk inactive
          </p>
          <p>
            Nous proposons des solutions dédiées à la formation interne et l’onboarding en entreprise,
            toujours avec la flexibilité & la simplicité de Teachizy, telle que la connexion OAuth Clerk.
          </p>
          <p class="mt-2 has-text-weight-bold">
            Cette fonctionnalité vous intéresse ?
            Veuillez nous contacter à <a href="mailto:allo@teachizy.fr">allo@teachizy.fr</a>
            pour en discuter !
          </p>
        </div>
        <OAuthClerkForm v-else />
      </template>
    </AppFeatureWrapper>
  </div>
</template>
