<script>
import APICMS from '@shared/services/API/CMS';

const cache = {};

export default {
  props: {
    tag: {
      type: String,
      default: 'a',
    },
    page: {
      type: String,
      required: true,
    },
    replaceContent: {
      type: Function,
      default: (val) => val,
    },
    hasClickDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    closeButtonText() {
      return this.$t('close_button');
    },
    pageNotFoundMessage() {
      return this.$t('page_not_found');
    },
  },
  methods: {
    alert(page) {
      this.$buefy.dialog.alert({
        size: 'is-small',
        message: `<div class="content">${this.replaceContent(page.content.rendered)}</div>`,
        confirmText: this.closeButtonText,
      });
    },
    showPage() {
      this.$emit('open');

      if (cache[this.page]) {
        this.alert(cache[this.page]);
        return;
      }

      const loader = this.$buefy.loading.open();
      APICMS.getPageBySlug(this.page)
        .then(([page]) => {
          if (!page) {
            this.$buefy.dialog.alert(this.pageNotFoundMessage);
            return;
          }

          cache[this.page] = page;
          this.alert(page);
        })
        .finally(() => loader.close());
    },
  },
  i18n: {
    messages: {
      en: {
        close_button: 'Close',
        page_not_found: 'This page no longer exists on our site',
      },
      fr: {
        close_button: 'Fermer',
        page_not_found: "Cette page n'existe plus sur notre site",
      },
    },
  },
};
</script>

<template>
  <component :is="tag" @click.prevent="!hasClickDisabled && showPage()">
    <slot />
  </component>
</template>
